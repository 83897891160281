import { cloneDeep, filter, findIndex, includes, intersection } from 'lodash'

class BookingForm {
    constructor() {
        document.addEventListener('alpine:init', () => {
            Iodine.setErrorMessage('email', window.bookingForm.validator.email)
            Iodine.setErrorMessage('numeric', window.bookingForm.validator.numeric)
            Iodine.setErrorMessage('required', window.bookingForm.validator.required)
            Iodine.setErrorMessage('truthy', window.bookingForm.validator.truthy)

            const guestModel = {
                id: 0,
                contact: {
                    title: '',
                    first_name: '',
                    last_name: '',
                    address: '',
                    postal_code: '',
                    city: '',
                    country: '',
                    telephone_country_code: '',
                    telephone_number: '',
                    email_address: '',
                    date_of_birth: '',
                    how_did_you_hear_about_us: '',
                    how_did_you_hear_about_us_other: '',
                },
                programme: null,
                extras: [],
                special_requirements: '',
            }

            Alpine.data('bookingForm', () => ({
                currentStep: null,
                maxGuests: 0,
                resorts: [],
                programmes: [],
                datePicker: null,
                formIsSubmitting: false,
                separateRooms: false,
                roomsRequired: 1,
                closedDates: [],
                closedDatesChina: [],

                fields: {
                    resort: null,
                    guests: [],
                    rooms: [],
                    stay: {
                        check_in: null,
                        check_out: null,
                    },
                    accepted_terms: false,
                    receive_marketing: false,
                },

                get totalGuests() {
                    return this.fields.guests.length
                },

                get resortProgrammes() {
                    if (!this.fields.resort) {
                        return []
                    }

                    return filter(this.programmes, programme => {
                        return includes(programme.resorts, this.fields.resort.id)
                    })
                },

                get resortRooms() {
                    if (!this.fields.resort) {
                        return []
                    }

                    return filter(this.rooms, room => {
                        return includes(room.resorts, this.fields.resort.id)
                    })
                },

                init() {
                    this.maxGuests = parseInt(this.$root.dataset.maxGuests)
                    this.resorts = JSON.parse(this.$root.dataset.resorts)
                    this.programmes = JSON.parse(this.$root.dataset.programmes)
                    this.extras = JSON.parse(this.$root.dataset.extras)
                    this.rooms = JSON.parse(this.$root.dataset.rooms)
                    this.closedDates = JSON.parse(this.$root.dataset.closedDates)
                    this.closedDatesChina = JSON.parse(this.$root.dataset.closedDatesChina)

                    this.changeStep('resort')

                    this.addGuest()
                },

                isCurrentStep(step) {
                    return step === this.currentStep
                },

                changeStep(step) {
                    this.currentStep = step
                    this.$refs.steps.scrollTop = 0

                    if (step === 'dates') {
                        this.$nextTick(() => {
                            this.bindDatePickers()
                        })
                    }

                    this.sendAnalyticsEvent(step)
                },

                validateField(element) {
                    const errorEl = element.parentElement.querySelector('.js-field-error')
                    const rules = JSON.parse(element.dataset.rules)
                    let value = null

                    switch (element.attributes.type.value) {
                        case 'checkbox':
                            value = element.checked
                            break

                        default:
                            value = element.value
                    }

                    const validator = Iodine.assert(value, rules)

                    if (validator.valid) {
                        //console.log('validator :>> ', validator);
                        errorEl.innerText = ''
                        element.parentElement.classList.remove('has-error')
                    } else {
                        errorEl.innerText = validator.error
                        element.parentElement.classList.add('has-error')
                    }

                    return validator.valid
                },

                sendAnalyticsEvent() {
                    if (typeof gtag === 'function') {
                        gtag('event', 'booking_form', {
                            stage: step
                        })
                    }
                },

                isSelectedResort(resort) {
                    if (!this.fields.resort) {
                        return false
                    }

                    return resort.id === this.fields.resort.id
                },

                selectResort(resort) {
                    if (this.fields.resort && resort.id !== this.fields.resort.id) {
                        this.resetGuests()
                    }

                    this.fields.resort = resort
                },

                resortStepIsInvalid() {
                    return !this.fields.resort
                },

                addGuest() {
                    if ((this.totalGuests + 1) <= this.maxGuests) {
                        const guest = cloneDeep(guestModel)
                        guest.id = this.totalGuests + 1

                        this.fields.guests.push(guest)
                    }
                },

                removeGuest() {
                    if (this.totalGuests > 1) {
                        this.fields.guests.pop()
                    }
                },

                resetGuests() {
                    this.fields.guests = []
                    this.addGuest()
                },

                isSelectedProgramme(programme, guestIndex) {
                    const guest = this.fields.guests[guestIndex]

                    if (!guest || !guest.programme) {
                        return false
                    }

                    if (programme.id !== guest.programme.id) {
                        return false
                    }

                    return true
                },

                selectProgramme(programme, guestIndex) {
                    const currentProgramme = this.fields.guests[guestIndex].programme

                    if (currentProgramme && programme.id !== currentProgramme.id) {
                        this.fields.guests[guestIndex].extras = []
                    }

                    this.fields.guests[guestIndex].programme = programme
                },

                programmeStepIsInvalid() {
                    let guestsWithNoProgramme = filter(this.fields.guests, guest => {
                        return !guest.programme
                    })

                    return (guestsWithNoProgramme.length > 0)
                },

                programmeExtras(guestIndex) {
                    const guest = this.fields.guests[guestIndex]

                    if (!guest || !guest.programme) {
                        return []
                    }

                    return filter(this.extras, extra => {
                        return includes(extra.programmes, guest.programme.id)
                    })
                },

                isSelectedExtra(extra, guestIndex) {
                    const guest = this.fields.guests[guestIndex]

                    if (!guest) {
                        return false
                    }

                    return (findIndex(guest.extras, ['id', extra.id]) > -1)
                },

                selectExtra(extra, guestIndex) {
                    const guest = this.fields.guests[guestIndex]
                    const extraIndex = findIndex(guest.extras, ['id', extra.id])

                    if (extraIndex < 0) {
                        guest.extras.push(extra)
                    } else {
                        guest.extras.splice(extraIndex, 1)
                    }
                },

                datesStepIsInvalid() {
                    return !this.fields.stay.check_in || !this.fields.stay.check_out
                },

                isSelectedRoom(room, index) {
                    const chosenRoom = this.fields.rooms[index - 1]

                    if (!chosenRoom) {
                        return false
                    }

                    if (room.id !== chosenRoom.id) {
                        return false
                    }

                    return true
                },

                selectRoom(room, index) {

                    if(this.separateRooms) {
                        room.guest = this.fields.guests[index - 1].id
                    }

                    this.fields.rooms[index - 1] = room
                },

                addSeparateRooms(separate) {
                    this.roomsRequired = separate ? this.totalGuests : Math.round(this.totalGuests / 2)
                },

                roomsStepIsInvalid() {
                    return this.fields.rooms.length != this.roomsRequired
                },

                validateDetails() {
                    let invalidFields = []

                    this.$root.querySelectorAll('[data-rules]').forEach((field, index) => {
                        //console.log('field :>> ', field);
                        if (!this.validateField(field)) {
                            invalidFields.push(index)
                        }
                    })

                    if (invalidFields.length === 0) {
                        this.changeStep('summary')
                    } else {
                        this.$refs.steps.scrollTo({
                            top: this.$refs.steps.querySelector('.has-error').offsetTop - 80,
                            behavior: 'smooth'
                        })
                    }
                },

                async submitForm() {
                    this.formIsSubmitting = true

                    try {
                        await fetch('/wp-json/cliniquelaprairie/v1/booking', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(this.fields),
                        })

                        this.sendAnalyticsEvent('complete')

                        window.location = this.$root.dataset.successRedirect
                    } catch (error) {
                        console.error('Error:', error)
                    }
                },

                getProgrammeValues(value) {
                    return this.fields.guests.map(guest => {
                        return guest.programme[value]
                    })
                },

                bindDatePickers() {
                    let min_days = Math.max(...this.getProgrammeValues('duration_min_days'))
                    let max_days = Math.max(...this.getProgrammeValues('duration_max_days'))
                    let arrival_days = intersection(...this.getProgrammeValues('arrival_days'))
                    let stay = this.fields.stay
                    let closed_days

                    //console.log('The Resort is: ' + this.fields.resort.id);
                    //this.fields.resort
                    //this.fields.resort.id
                    //this.resorts
                    //resort.id 

                    //switzerland id: clinique-la-prairie-health-resort-montreux
                    //China id: clinique-la-prairie-health-resort-anji

                    if(this.fields.resort.id === 'clinique-la-prairie-health-resort-montreux') {
                        closed_days = this.closedDates
                    } else {
                        closed_days = this.closedDatesChina
                    }

                    if(arrival_days.length == 0) {
                        return console.log("These programmes are incompatible")
                    }

                    if(this.datePicker) {
                        this.datePicker.destroy()
                    }

                    this.datePicker = new easepick.create({
                        element: document.getElementById(`datepicker`),
                        css: [
                            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
                            '/wp-content/themes/cn-theme-cliniquelaprairie/widgets/booking-form/css/easepick.css',
                        ],
                        inline: true,
                        calendars: 2,
                        grid: 2,
                        format: 'DD/MM/YYYY',
                        plugins: [
                            'LockPlugin',
                            'RangePlugin',
                        ],
                        LockPlugin: {
                            minDate: new Date(),
                            minDays: parseInt(min_days),
                            maxDays: parseInt(max_days),

                            filter(date, picked) {

                                //Disable Dates from ACF fields in CMS
                                const formattedDate = date.format('DD/MM/YYYY');
                                //const disabledDates = ['2024-11-03', '2024-11-10', '2024-11-17'];
                                const disabledDates = closed_days;

                                // Disable specific dates
                                if (disabledDates.includes(formattedDate)) {
                                    return true; // This disables the date
                                }


                                if (picked.length === 1) {

                                    // We have a check-in date, lock any dates before then for check-out
                                    if (date < picked[0]) {
                                        return true
                                    }

                                    // Allow any check-out date to be selected within min/maxDays
                                    return false
                                }

                                const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

                                return !arrival_days.includes(days[date.getDay()])

                            }
                        },

                        setup(picker) {
                            picker.on('select', event => {
                                const { start, end } = event.detail

                                stay.check_in = new Date(start).toLocaleDateString()
                                stay.check_out = new Date(end).toLocaleDateString()
                            })
                        }
                    })
                }
            }))
        })
    }
}

export default BookingForm
